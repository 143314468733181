import React from "react";
import RedirectHeader from "../components/RedirectHeader";
import { FaChevronRight } from "react-icons/fa";
import Layout from "../components/Layout";
import "./prices-and-schedule.sass";
import { Link } from "gatsby";

const PricesAndSchedulePage = () => {
  return (
    <Layout>
      <div className="section-container" style={{
        marginBottom: "12rem"
      }}>
        <section className="section">
          <RedirectHeader title="CENY & TERMINY" showRedirect={false} />
        </section>
        <div className="list-container">
          <Link to="/prices" className="list-item">
            <h5 className="h5-black">01</h5>
            <h5 className="h5-black">CENY</h5>
            <FaChevronRight />
          </Link>
          <Link to="/schedule" className="list-item">
            <h5 className="h5-black">02</h5>
            <h5 className="h5-black">TERMINY</h5>
            <FaChevronRight />
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default PricesAndSchedulePage;